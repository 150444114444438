.Button {
  text-align: center;
}

.Login {
  width: 250px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  color: #fff;
  padding: 50px;
  border: 1px solid #8b8b8b;
  border-radius: 10px;
}

.Login > form > input {
  margin-top: 10px;
  border-radius: 5px;
  font-size: 18px;
}

.Button-header {
  background: -webkit-linear-gradient(360deg,#383836 10%,#4a4a4a 360%); background: linear-gradient(360deg,#383836 10%,#4a4a4a 360%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.lastAlertText {
  padding-top: 40px;
  text-shadow: 2px 4px 5px #000;
  font-size: 24px;
}

.connectionsText {
  padding-top: 16px;
  text-shadow: 1px 3px 4px #000;
  font-size: 18px;
}

.connectingView {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.red {
  color: rgba(114, 2, 2, 0.678) !important;
}
.green {
  color: rgba(2, 114, 17, 0.678) !important;
}

.btn { /*dent around button*/
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: rgba(94, 94, 94, 0.66);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  text-align: center;
  background: #f7f7f7;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.08);
}
.btn .fa { /*Button itself*/
  position: absolute;
  content: '';
  width: 180px;
  height: 180px;
  line-height: 180px;
  vertical-align: middle;
  left: 10px;
  top: 9px;
  border-radius: 50%;
  font-size: 100px;
  background-image: -webkit-linear-gradient(#e8e8e8 0%, #d6d6d6 100%);
  background-image: linear-gradient(#e8e8e8 0%, #d6d6d6 100%);
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.66);
  box-shadow: inset 0 2px 0 rgba(255,255,255,0.5), 0 2px 2px rgba(0, 0, 0, 0.19);
  border-bottom: solid 2px #b5b5b5;
}

.btn .fa:active {
  background-image: -webkit-linear-gradient(#efefef 0%, #d6d6d6 100%);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 2px 2px rgba(0, 0, 0, 0.19);
  border-bottom: solid 2px #d8d8d8;
}

.pgreen {
  background: rgba(2, 114, 17, 0.678) !important;
  animation: pulseGreen 2s infinite !important;
}
.pred {
  background: rgba(104, 4, 1, 0.678) !important;
  animation: pulseRed 2s infinite !important;
}
.pulse {
  display: inline-block;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background: rgba(22, 22, 22, 0.678);
  cursor: pointer;
  box-shadow: 0 0 0 rgba(44, 204, 105, 0.4);
  animation: none;
}


@-webkit-keyframes pulseGreen {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 204, 105, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 80px rgba(44, 204, 105, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(44, 204, 105, 0);
  }
}
@keyframes pulseGreen {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 204, 105, 0.4);
    box-shadow: 0 0 0 0 rgba(44, 204, 105, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 80px rgba(44, 204, 105, 0);
      box-shadow: 0 0 0 80px rgba(44, 204, 105, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(44, 204, 105, 0);
      box-shadow: 0 0 0 0 rgba(44, 204, 105, 0);
  }
}

@-webkit-keyframes pulseRed {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(201, 8, 2, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 80px rgba(201, 8, 2, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(201, 8, 2, 0);
  }
}
@keyframes pulseRed {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(201, 8, 2, 0.4);
    box-shadow: 0 0 0 0 rgba(201, 8, 2, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 80px rgba(201, 8, 2, 0);
      box-shadow: 0 0 0 80px rgba(201, 8, 2, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(201, 8, 2, 0);
      box-shadow: 0 0 0 0 rgba(201, 8, 2, 0);
  }
}


#pushEnableButton, #workerTestButton {
  font-size: 1.4em;
  color: #2610a1;
  text-decoration: underline;
  cursor: pointer;
}
